/**
 * Created by wangtong on 2021/5/17
 */

import {ApiV1Service, RequestConfig, Post, Get} from "@/api/base";
import * as models from "@/models"
import {Convert} from "@sandan/febasic";

class CommonService extends ApiV1Service {
	@Post('/common/visit_official', {header: "application/x-www-form-urlencoded"})
	async visitOfficial(payload: models.CommonVisitOfficialRequest, config?: RequestConfig): Promise<{}> {
		return this.request(payload, config)
	}
	
	@Post('/common/download_app')
	async downloadApp(payload: models.CommonDownloadAppRequest, config?: RequestConfig): Promise<{}> {
		return this.request(payload, config)
	}
	
	@Post('/common/open_app')
	async openApp(payload: models.CommonOpenAppRequest, config?: RequestConfig): Promise<{}> {
		return this.request(payload, config)
	}
	
	@Post('/common/click_download_button')
	async clickDownloadButton(payload: models.CommonClickDownloadButtonRequest, config?: RequestConfig): Promise<{}> {
		return this.request(payload, config)
	}
	
	@Post('/common/upload/image', {header: "multipart/form-data"})
	async uploadImage(dir: string, file: File[] | File | string, config?: RequestConfig): Promise<{ items: models.ImageInfo[] }> {
		if (typeof file === "string") {
			if (file.startsWith("data")) {
				file = Convert.base64ToFile(file, "upload.jpg")
			} else {
				return {
					items: [{
						id: 0,
						path: file,
						width: 0,
						height: 0,
						size: 0,
						format: 'jpg',
						md5: ""
					}]
				}
			}
		}
		
		return this.request({
			file: Convert.toList(file),
			dir: dir
		}, config)
	}
}

export const CommonApi = new CommonService()

