/**
 * Created by wangtong on 2021/5/15
 */
import {Api, Convert} from "@sandan/febasic";
import {AxiosRequestConfig, AxiosResponse, AxiosError} from "axios";
import RequestConfig = Api.RequestConfig;
import Post = Api.Post;
import Get = Api.Get;

@Api.Controller("/official-api/v1")
export class ApiV1Service extends Api.Service {
	protected async beforeRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
		if (config.method === "get") {
			console.log(`Before ${config.method} ${config.url}`, config.params)
		} else {
			console.log(`Before ${config.method} ${config.headers['Content-Type']} ${config.url}`, config.data)
		}
		return super.beforeRequest(config);
	}
	
	protected async afterResponse(response: AxiosResponse<Api.Response>): Promise<AxiosResponse> {
		let status = response.status || 0;
		let code = (response.data || {}).code || 0;
		let message = (response.data || {}).message || '';
		let advice = (response.data || {}).advice || '';
		console.log(`After ${response.config.method} ${response.config.url}`)
		console.log((response.data || {}).data)
		
		return super.afterResponse(response);
	}
	
	protected afterResponseError(error: AxiosError<Api.Response>) {
		let response = error.response || {
			status: '',
			data: {code: 0, advice: '', message: ''},
			config: {method: "get", url: '', params: ''}
		}
		let status = response.status || 200;
		let code = (response.data || {}).code || 0;
		let message = (response.data || {}).message || '';
		let advice = (response.data || {}).advice
		let config = response.config || {}
		
		if (status > 399) {
			
			if (config.method === "get") {
				console.log(`After Error ${config.method} ${config.url}`, config.params)
			} else {
				console.log(`After Error ${config.method} ${config.url}`, config.data)
			}
			
			console.error(`${status} ${code} ${message} ${advice}`)
			
			throw message
		}
		
		
		return super.afterResponseError(error);
	}
	
}

export {
	Api,
	Post,
	Get,
	RequestConfig,
};

